import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

interface FAQAnswerProps {
  children: React.ReactNode;
}

const FAQAnswer: React.FC<FAQAnswerProps> = ({ children }) => {
  return <div className="mt-2 text-lg text-gray-300">{children}</div>;
};

const FAQ = () => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const faqItems = [
    {
      question: "What payment methods do you have?",
      answer: (
        <FAQAnswer>
          We accept all major payment methods available through Sellix. You can
          securely complete your purchase directly on our website at{" "}
          <Link to="/store">
            <span className="text-blue-600 underline hover:text-blue-400">
              our store
            </span>{" "}
          </Link>
          .
        </FAQAnswer>
      ),
    },
    {
      question: "Are your plugins fully automated? What are one-click plugins?",
      answer: (
        <FAQAnswer>
          Our plugins are not fully automated, meaning you are always in control
          of the actions performed. One-click plugins simplify complex tasks by
          providing a menu option at your cursor for the next expected action,
          but you still need to click manually.
        </FAQAnswer>
      ),
    },
    {
      question: "Is there an instance limit?",
      answer: (
        <FAQAnswer>
          Yes, there is a limit based on the total monthly value(before
          discounts) of your active licenses:
          <ul className="ml-4 list-disc">
            <li>€0-9.99: 1 instance</li>
            <li>€10-19.99: 2 instances</li>
            <li>€20-29.99: 3 instances</li>
          </ul>
          For every additional €10, one more instance is added.
          <p />
          This means that if you own a 12 month All Plugins Bundle License, it
          will still count as €26.99 worth of license value per month.
        </FAQAnswer>
      ),
    },
    {
      question: "How do I cancel my subscription?",
      answer: (
        <FAQAnswer>
          You can manage or cancel your subscriptions directly via the Sellix
          Customer Portal. You should have received a confirmation email with
          your order that includes a cancellation link. Alternatively, visit the{" "}
          <Link to="/store">
            <span className="text-blue-600 underline hover:text-blue-400">
              Sellix Customer Portal
            </span>{" "}
          </Link>
          to cancel.
        </FAQAnswer>
      ),
    },
    {
      question: "Are your plugins safe?",
      answer: (
        <FAQAnswer>
          Yes, our plugins are designed with safety in mind when used
          responsibly. Follow the directions provided on the plugin page and
          avoid overusing features marked with ⚠️. Do not combine them with
          unauthorized third-party tools like auto-clickers. Note: all plugins
          technically violate Jagex rules, so use them at your own risk.
        </FAQAnswer>
      ),
    },
    {
      question: "What happens if I get hit?",
      answer: (
        <FAQAnswer>
          While our plugins are designed to minimize risk, misuse can lead to
          bans. We cannot offer refunds or compensation for bans, so we
          recommend following our usage guidelines carefully to protect your
          account.
        </FAQAnswer>
      ),
    },
    {
      question: "Can I request custom plugins?",
      answer: (
        <FAQAnswer>
          Yes, we are open to user suggestions! Visit the suggestions channel on
          discord to submit your ideas. We prioritize development based on
          demand and the feasibility of requested features.
        </FAQAnswer>
      ),
    },
    {
      question: "Do you offer refunds?",
      answer: (
        <FAQAnswer>
          No, refunds are not available once a license is purchased. Please
          review plugin descriptions and reach out to us if you have questions
          before making a purchase. Our team is happy to assist with any
          clarifications.
        </FAQAnswer>
      ),
    },
    {
      question: "Do plugins work with Runelite updates?",
      answer: (
        <FAQAnswer>
          Yes, our plugins are updated regularly to ensure compatibility with
          the latest versions of RuneLite. If there's ever a compatibility
          issue, we prioritize resolving it as quickly as possible.
        </FAQAnswer>
      ),
    },
    {
      question: "Can I use the same account on multiple devices?",
      answer: (
        <FAQAnswer>
          Yes, you can use the same account on multiple devices as long as you
          don't exceed the instance limit associated with your active licenses.
          This allows flexibility for your gameplay setup.
        </FAQAnswer>
      ),
    },
    {
      question: "Do you offer trials or short-term licenses?",
      answer: (
        <FAQAnswer>
          Currently, we do not offer trials or licenses shorter than one month.
        </FAQAnswer>
      ),
    },
    {
      question: "Are there any discounts?",
      answer: (
        <FAQAnswer>
          Yes, we offer discounts when purchasing licenses for multiple months.
          You can receive up to <strong>40% off</strong> depending on the
          duration. Visit our pricing page for more details.
        </FAQAnswer>
      ),
    },
  ];

  const toggleExpand = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Header />
      <div className="px-5 xl:mx-96 xl:mt-40 xl:px-32">
        <h1
          className="mb-10 mt-20 text-center text-4xl font-bold text-white"
          data-aos="zoom-y-out"
        >
          Frequently Asked Questions
        </h1>
        <div
          className="space-y-4 xl:mx-60"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          {faqItems.map((item, index) => (
            <div key={index} className="border-b border-gray-700 pb-4">
              <button
                onClick={() => toggleExpand(index)}
                className="flex w-full items-center justify-between text-left text-2xl font-medium text-white hover:text-purple-600 focus:outline-none"
              >
                {item.question}
                <span
                  className={`ml-3 transform transition-transform ${
                    expanded === index ? "rotate-180" : ""
                  }`}
                >
                  ▼
                </span>
              </button>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  expanded === index
                    ? "max-h-screen opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
