import React from "react";
import { Link, useLocation } from "react-router-dom";
import AccessFailPng from "../../assets/access-fail.png";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const FailureReasons = {
  "generic-error": {
    message:
      "Something went wrong with your login. Please try again or contact us on Discord for assistance.",
    action: {
      text: "Join Discord",
      link: "/discord",
    },
  },
  "user-not-found": {
    message:
      "Looks like you have not yet made a purchase, head over to our store to make your first purchase.",
    action: {
      text: "Visit Store",
      link: "/store",
    },
  },
  "email-not-verified": {
    message:
      "Your Discord email is not yet verified. Head over to Discord account settings and verify your email.",
    action: {
      text: "Verify Email",
      link: "https://support.discord.com/hc/en-us/articles/213219267-Resending-Verification-Email",
    },
  },
  "user-disabled": {
    message:
      "Seems like this user has been disabled. For more information, contact us on Discord.",
    action: {
      text: "Join Discord",
      link: "/discord",
    },
  },
  "instance-limit-exceeded": {
    message:
      "You've reached your current maximum instance limit. If you think this is an error please wait 10 minutes and try again or contact us on Discord.",
    action: {
      text: "Join Discord",
      link: "/discord",
    },
  },
};

function LoginFailed() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const failureCode = searchParams.get("code") || "generic-error";

  const failureReason =
    FailureReasons[failureCode as keyof typeof FailureReasons] ||
    FailureReasons["generic-error"];

  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/* Site header */}
      <Header />

      <main className="flex flex-grow flex-col items-center px-5 pt-20 text-center xl:mx-96 xl:px-32 xl:pt-40">
        <div className="container mx-auto flex flex-col items-center">
          <img src={AccessFailPng} className="mx-auto  h-[250px]" alt="" />
          <p className="my-6 text-3xl font-normal text-secondary-200">
            {failureReason.message}
          </p>
          {failureReason.action && (
            <Link
              to={failureReason.action.link}
              className="btn-gradient my-5 flex w-full items-center justify-center rounded-lg px-6 py-3 transition duration-150 ease-in-out hover:bg-white md:relative md:my-0 md:flex md:w-[218px] md:gap-2.5 md:rounded-2xl"
            >
              <p className="flex-shrink-0 flex-grow-0 text-center text-2xl font-semibold text-white">
                {failureReason.action.text}
              </p>
            </Link>
          )}
        </div>
      </main>

      {/* Site footer */}
      <Footer />
    </div>
  );
}

export default LoginFailed;
