import React from "react";
import { Link } from "react-router-dom";
import Cart from "../../assets/Cart";
import { useGlobalContext } from "../../context/useGlobalContext";
import {
  Category,
  IdentifiedSellixBundle,
  IdentifiedSellixPlugin,
} from "../../services/sellix";

interface ProductCardProps {
  product: IdentifiedSellixBundle | IdentifiedSellixPlugin;
  id: string;
  img: string;
  name: string;
  version: string;
  price: number;
  category?: string;
  categories?: Array<Category>;
  border?: boolean;
}

function ProductCard(props: ProductCardProps) {
  const { setSelectedProduct } = useGlobalContext();
  const handleAddToCart = () => {
    setSelectedProduct(props.product);
  };

  return (
    <div
      className={`relative h-full w-[280px] rounded-lg bg-[#1B1924] ${
        props.border ? "border-x-1 border-b-1 border-black" : ""
      }`}
    >
      <div className="mb-5 flex w-full items-end justify-center">
        <img
          src={props.img}
          alt=""
          className="h-[150px] w-full rounded-lg rounded-b-none"
        />
      </div>
      <div className="p-3">
        <div id="divToGrow" className="flex flex-grow">
          <p className="flex-wrap justify-start truncate text-left text-base font-semibold text-white">
            {props.name}
          </p>
          <div className="flex-grow" />
          <p className="flex-wrap justify-end text-left text-base font-medium text-white">
            {props.version}
          </p>
        </div>
        <div className="mt-4 flex border-t border-secondary-200 pt-5">
          <div>
            {props.price > 0 ? (
              <>
                <p className="text-left text-sm text-gray-500">Starting at</p>
                <p className="text-left text-base text-white">
                  {props.price.toFixed(2)}€/mo
                </p>
              </>
            ) : (
              <p className="text-left text-xl font-bold text-green-500">FREE</p>
            )}
          </div>
          <div className="flex-grow" />
          <div className="flex justify-end space-x-2">
            <Link
              to={
                props.category === "Bundle"
                  ? `/bundle-details/${props.id}`
                  : `/plugin-details/${props.id}`
              }
              className="relative flex h-[40px] w-[73px] items-center justify-center rounded-lg border border-secondary-100 text-secondary-100 transition duration-150 ease-in-out hover:text-white"
            >
              <p className="flex-shrink-0 flex-grow-0 text-left text-base font-medium">
                View
              </p>
            </Link>
            {props.price > 0 && (
              <button
                onClick={() => handleAddToCart()}
                className="relative flex h-[40px] w-[40px] items-center justify-center rounded-lg border-1 border-green-600 text-white transition duration-150 ease-in-out hover:bg-green-600"
              >
                <Cart />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
