import React from "react";
import { Link } from "react-router-dom";
import KuriLogo from "../../assets/kuri-logo.png";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

function LoginSuccess() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/* Site header */}
      <Header />

      <main className="flex flex-grow flex-col items-center px-5 pt-10 text-center xl:mx-96 xl:px-32 xl:pt-40">
        <div className="container mx-auto flex flex-col items-center">
          <img src={KuriLogo} alt="Kuri Logo" className="mb-6" />
          <p className="text-xl font-normal text-secondary-200">
            Login successful. You can close this window or navigate to our{" "}
            <Link to="/">
              <span className="text-blue-600 underline">home</span>{" "}
            </Link>
            to explore.
          </p>
        </div>
      </main>

      {/* Site footer */}
      <Footer />
    </div>
  );
}

export default LoginSuccess;
