import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useGlobalContext } from "../../context/useGlobalContext";
import { Category, IdentifiedSellixPlugin } from "../../services/sellix";

const PluginDetails = () => {
  const { plugins } = useGlobalContext();

  const { setSelectedProduct } = useGlobalContext();
  const { id } = useParams<{ id: string }>(); // Assuming `id` is of type string
  const [plugin, setPlugin] = useState<IdentifiedSellixPlugin | undefined>();
  const [mdFile, setMdFile] = useState<any>("");

  useEffect(() => {
    let foundPlugin: any = plugins.find(
      (p: IdentifiedSellixPlugin) => p.id === id,
    );
    if (!foundPlugin) return;

    setPlugin(foundPlugin);

    // Fetch the README file for the found plugin
    fetch(`${process.env.PUBLIC_URL}/assets/${foundPlugin.id}/README.md`)
      .then((res) => res.text())
      .then((md) => {
        setMdFile(md);
      });
  }, [id, plugins]); // Only depend on `id`, not `plugins`

  if (!plugin) {
    return (
      <div className="flex min-h-screen flex-col overflow-hidden">
        <Header />
        <div className="flex-grow"></div>
        <Footer />
      </div>
    );
  }

  const handlePurchase = () => {
    setSelectedProduct(plugin);
  };

  const getFilteredCategories = (categories: Category[]) => {
    const newCategory = {
      id: "all-public-plugins",
      name: "All Plugins",
    };

    return categories
      .concat(newCategory)
      .filter((category) => category.id !== "free");
  };

  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Header />

      <div className="pt-10 md:pt-40">
        <section className="px-5 py-14 sm:px-6 md:mx-20 xl:mx-40">
          <div className="pb-12 md:pb-20">
            <div className="mb-10">
              <h1
                className={`text-center text-3xl font-medium text-white sm:text-4xl md:text-5xl lg:text-6xl`}
                data-aos="slide-right"
                data-aos-delay="150"
              >
                {plugin.name}
              </h1>
            </div>
            <div className="flex flex-col justify-center ">
              <div className="flex flex-col justify-center lg:flex-row">
                {/* Left Panel */}
                <div
                  className="mb-12 w-auto rounded-lg border-black bg-background-500 p-4 font-semibold text-white shadow-rightBottom lg:mx-4  lg:w-1/3"
                  data-aos="slide-up"
                  data-aos-delay="150"
                >
                  <div className="mb-5 flex justify-between">
                    <span className="text-left text-sm italic text-yellow-300">
                      Type:{" "}
                      {plugin.categories.map((ele) => (
                        <span>{ele.name} </span>
                      ))}
                    </span>
                    <span className="text-left text-sm italic text-yellow-300">
                      Plugin Version: {plugin.version}
                    </span>
                  </div>
                  {/* ReadMe File */}
                  <div>
                    <Markdown
                      children={mdFile}
                      components={{
                        h2: ({ children, ...rest }) => (
                          <h2
                            className="mb-3 mt-3 border-b-1 border-primary-100 text-lg font-bold text-primary-100"
                            {...rest}
                          >
                            {children}
                          </h2>
                        ),
                        a: ({ children, ...rest }) => (
                          <Link
                            className="text-primary-200 underline"
                            to={rest.href as string}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {children}
                          </Link>
                        ),
                        p: ({ children, ...rest }) => (
                          <p className="text-sm">{children}</p>
                        ),
                        ul: ({ children, ...rest }) => (
                          <ul className="marker:text-primary-200 [&>li]:list-disc">
                            {children}
                          </ul>
                        ),
                        ol: ({ children, ...rest }) => (
                          <ol className="marker:text-primary-200 [&>li]:list-decimal">
                            {children}
                          </ol>
                        ),
                        li: ({ children, ...rest }) => (
                          <li className="list-de ml-5 text-sm">{children}</li>
                        ),
                        pre: ({ children, ...rest }) => (
                          <pre className="my-1">{children}</pre>
                        ),
                        code: ({ children, ...rest }) => (
                          <code className="mr-1 rounded-md bg-gray-600 px-1 text-sm">
                            {children}
                          </code>
                        ),
                      }}
                    />
                  </div>
                </div>

                {/* Right Panel */}
                <div
                  className="w-auto self-start rounded-lg bg-background-500 font-semibold text-white shadow-rightBottom lg:mx-4 lg:w-80"
                  data-aos="slide-left"
                  data-aos-delay="150"
                >
                  <img
                    className="rounded-lg"
                    src={
                      plugin.price.amount > 0
                        ? plugin.sellixProducts[0].imageUrl
                        : `/assets/${plugin.id}/thumbnail.jpg`
                    }
                    alt="Plugin Thumbnail"
                  />
                  {/* Right panel labels and buttons */}

                  {plugin.price.amount > 0 ? (
                    <>
                      <div className="space-y-5 px-5 pt-5">
                        <div className="flex justify-between">
                          <label className="text-right">Price</label>
                          <span className="ml-2 text-right">
                            {plugin.price.amount.toFixed(2)}€
                          </span>
                        </div>
                      </div>

                      <div className="mt-4 flex justify-center gap-y-5 px-5 py-4">
                        <button
                          className="btn-gradient mr-2 h-9 w-full rounded-xl font-semibold text-white transition-colors hover:bg-white"
                          onClick={handlePurchase}
                        >
                          Purchase
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="mx-5 mt-5 space-y-6">
                      <p className="text-center text-lg font-medium text-white">
                        Free with the purchase of:
                      </p>
                      <div className="space-y-4">
                        {getFilteredCategories(plugin.categories).map(
                          (category, index) => (
                            <div
                              key={category.id}
                              className={`flex items-center justify-between ${
                                index ===
                                getFilteredCategories(plugin.categories)
                                  .length -
                                  1
                                  ? ""
                                  : "border-b border-gray-300"
                              } pb-4`}
                            >
                              <span className="text-lg font-semibold text-white">
                                {category.name} Bundle
                              </span>
                              <Link
                                to={`/bundle-details/${category.id}`}
                                className="btn-gradient flex items-center justify-center rounded-md px-6 py-2 transition-all duration-150 ease-in-out hover:bg-white hover:shadow-lg md:rounded-lg"
                              >
                                <p className="text-base font-semibold text-white">
                                  View
                                </p>
                              </Link>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PluginDetails;
